import styles from './FAQ.module.css';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { faqs } from '../../data/faqs';
import PrimarySection from '../../components/Section/PrimarySection';
import Container from '../../components/Container/Container';
import PageTitle from '../../components/Typography/PageTitle';
import FAQAccordion from '../../components/FAQAccordion/FAQAccordion';

export default function FAQ() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Frequently Asked Questions</title>
        </Helmet>
      </HelmetProvider>
      <PrimarySection className={styles.Section}>
        <Container>
          <PageTitle textAlign="center" className={styles.Title}>
            Frequently Asked Questions
          </PageTitle>
          <div className="col-12 col-lg-10 p-0 mx-auto">
            {faqs.map((faq, i) => (
              <FAQAccordion key={i} question={faq.question}>
                {faq.answer}
              </FAQAccordion>
            ))}
          </div>
        </Container>
      </PrimarySection>
    </>
  );
}
